<template>
  <div class="data-generate">
    <div class="generate-content">
      <div class="generate-area">
        <div class="data-source">
          <div class="attack">
            <div class="custom-block inner">
              <h4 class="sub-title attack">攻击方</h4>
              <el-image :src="data.attackUrl" v-if="data.attackUrl" lazy fit="contain">
                <div slot="placeholder" v-loading="true" class="loading"></div>
              </el-image>
              <div v-else class="uploader" @click="chooseExampleFile('attackUrl')">
                <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
              </div>
              <div class="upload-desc" :class="data.loading ? 'disabled' : ''">
                <div class="upload-btn">
                  <div class="upload-text" @click="chooseExampleFile('attackUrl')">
                    <p class="text">上传图片</p>
                  </div>
                </div>
                <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
              </div>
            </div>
          </div>
          <div class="under-attack">
            <div class="custom-block inner">
              <h4 class="sub-title">被攻击方</h4>
              <el-image :src="data.underAttackUrl" v-if="data.underAttackUrl" lazy fit="contain">
                <div slot="placeholder" v-loading="true" class="loading"></div>
              </el-image>
              <div v-else class="uploader" @click="chooseExampleFile('underAttackUrl')">
                <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
              </div>
              <div class="upload-desc" :class="data.loading ? 'disabled' : ''">
                <div class="upload-btn">
                  <div class="upload-text" @click="chooseExampleFile('underAttackUrl')">
                    <p class="text">上传图片</p>
                  </div>
                </div>
                <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="custom-block result-box">
          <h4 class="sub-title result">生成结果</h4>
          <div class="image-cover" v-if="!data.loading">
            <el-image :src="data.resultUrl" v-if="data.resultUrl" lazy fit="contain">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <div class="no-data-panel" v-else>
              <img src="@/assets/img/icon-no-data.png" alt="" width="120" height="120">
              <p>暂无数据</p>
            </div>
          </div>
          <div class="qr-scanner" v-if="data.loading">
            <div class="box">
              <div class="line"></div>
              <div class="angle"></div>
            </div>
            <p class="tip">生成中</p>
          </div>
          <div class="export-desc" v-if="data.resultUrl && !data.loading">
            <div class="export-btn" @click="downloadImage">
              <img src="@/assets/img/icon-export.png" alt="" width="16" height="16">
              <span>导出</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="footer">
      <el-button type="primary" @click="onCreate" :disabled="btnDisabled">开始生成</el-button>
    </div>

    <choose-example-radio
      v-if="chooseShowRadio"
      :dataList="customExampleList"
      @closeDialogItem="hideExampleRadio"
      :type="activeKey"
      :fileType="fileType"
      @onChoose="onChoose">
    </choose-example-radio>
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { Loading } from 'element-ui';
import { STORAGE, CODE_CLASS } from '@/utils/const';
import { downloadImage, goLoginPageAndInitData } from '@/utils';
import ChooseExampleRadio from "@/components/ChooseExampleRadio.vue";

const ACTIVE_KEY = 'imageAttackData';
export default {
  name: "face-change-by-image",
  components: {
    "choose-example-radio": ChooseExampleRadio,
  },
  data() {
    return {
      activeKey: ACTIVE_KEY,
      chooseShowRadio: false,
      fileType: CODE_CLASS.moduleKey[ACTIVE_KEY],
      activeUploadKey: '', // 上传后需要对应的字段
    }
  },

  mounted() {
    this.getDemo();
  },

  computed: {
    demoKey() {
      return CODE_CLASS.demoKey[`${ACTIVE_KEY}_${this.activeUploadKey}`];
    },
    customExampleList() {
      return this.activeUploadKey ? state.demoData[this.demoKey] : [];
    },
    data() {
      return state[ACTIVE_KEY] || {};
    },
    btnDisabled() {
      return !this.data.attackUrl || !this.data.underAttackUrl || this.data.loading;
    },
  },

  methods: {
    getDemo() {
      ['attackUrl', 'underAttackUrl'].map(key => {
        dispatch(ACTIONS.getDemo, {fileType: CODE_CLASS.demoKey[`${ACTIVE_KEY}_${key}`]});
      })
    },

    commitData(data) {
      commit(TYPE.SAVE_IMAGE_ATTACK_DATA, data);
    },

    // 开始生成
    onCreate() {
      let {attackUrl, underAttackUrl} = this.data;
      dispatch(ACTIONS.imageAttack, {imageAttack: attackUrl, imageTarget: underAttackUrl});
    },

    downloadImage() {
      let {resultUrl} = this.data;
      resultUrl && downloadImage(resultUrl);
    },

    // 选择示例
    chooseExampleFile(key) {
      if (this.data.loading) {
        return;
      }
      this.chooseShowRadio = true;
      this.activeUploadKey = key;
    },
   
    hideExampleRadio(){
      this.chooseShowRadio = false;
    },

    // 选择
    onChoose(fileUrl) {
      this.chooseShowRadio = false;
      this.commitData({...this.data, [this.activeUploadKey]: fileUrl, resultUrl: ''});
    },
  },
};
</script>

<style lang="less">
</style>
